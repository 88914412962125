<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
})
const slots = defineSlots<{
  default: any
  header?: any
}>()
</script>

<template>
  <div class="max-w-full mt-8 p-6 bg-white rounded-lg shadow-sm">
    <div v-if="props.title !== null || slots.header" class="border-b-2 border-gray-200 mb-[25px] mr-[-24px] ml-[-24px] pl-6">
      <div class="mb-6">
        <h4 v-if="props.title" class=" font-semibold tracking-tight text-gray-900 dark:text-white">
          {{ props.title }}
        </h4>
        <slot v-if="slots.header" name="header" />
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
